import { useState, useEffect, useContext } from "react";
import {
  AiFillMinusCircle,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import UploadModal from "containers/Modals/UploadModal";
import PresentationSlider from "../PresentationSlider/PresentationSlider";
import TemplateCard from "../TemplateCard/TemplateCard";
import "./style.scss";
import { deleteFilesByUrls } from "helpers/fileUpload";
import { useMutation, useQuery } from "relay-hooks";
import { showErrorToast, showSuccessToast } from "@learnio-as/toast";
import EditShowroomMutation from "graphql/mutations/EditShowroomMutation";
import responseHandler from "helpers/responseHandler";
import { fileUploadPrefixes } from "constants/others";
import { showroomTemplates } from "../../../../constants/templates";
import { useParams } from "react-router-dom";
import DocFrame from "../DocFrame";
import { TemplateContext } from "context/TemplateContentContext";
import { Modal } from "react-bootstrap";
import PresentationsQuery from "graphql/queries/GetPresentationQuery";
import { getThumbnail } from "helpers/thumbnailHandler";
import { VideoPlayer } from "@learnio-as/video-player";
import useWindowDimensions from "../windowDimensions";
import { useTranslation } from "react-i18next";

export default function Carousel({ key, list,index, templateId, rowIndex, onChangeEditor, isPreview, showroomData, data, slideNumber, itemsLength, className, isMicrosite, setViewingItem, imageChangesQueue, setImageChangesQueue,handleAdd, createShowroom, setIsUpdating,itemsClass,setRowKey }) {
  const [x, setX] = useState(0);
  const [show, toggleShow] = useState(false);
  const[cardIndex, setCardIndex] = useState(0);
  const [presFiles, setPresFiles] = useState([]);
  const [currentClickedPresentationId, setCurrentClickedPresentationId] = useState(null);
  const [deleteState, setDeleteState] = useState([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const dimensions  = useWindowDimensions();
  const { context, setContext } = useContext(TemplateContext);
  const [currentPresentationIndex, setCurrentPresentationIndex] = useState(0);
  if (
    templateId === undefined ||
    templateId === undefined ||
    templateId === null
  ) {
    templateId = parseInt(id);
  }
  const showroomTemplate = showroomTemplates.find(
    item => item?.id === templateId
  );
  const emptyRooms = showroomTemplate?.numberOfItems - data?.content?.length;
  // let templateArray = [] ;
  // for(let i = 0; i<emptyRooms; i++){
  //   templateArray.push({template:i});
  // }
  const goLeft = () => {
    setX(x + 100);
    setCardIndex(cardIndex - 1);
  };
  const goRight = () => {
    setX(x - 100);
    setCardIndex(cardIndex + 1);

  };

  const getPresentationFiles = presentationId => {
    const content = data?.content
      ?.filter(item => item.presentationId === presentationId)
      ?.at(0);
    return content?.files;
  };
  const [sliderPopUp, setSliderPopUp] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const deletePresentationOnCreation = newContent => {
    setContext({
      ...context,
      showroomDetails: {
        ...context.showroomDetails,
        content: newContent,
      },
    });
  };

  const handleDeletePresentation = (presId, rowIndex) => {
    let newShowromData = { ...showroomData };

    const {
      _id: editShowroomId,

      accountId,
      updatedAt,
      templateId,
      createdAt,
      creator,
      content,
      headerImage,
      ...showroom
    } = newShowromData;

    let arrContent = [];
    let innerContent = [];
    for (let i = 0; i < newShowromData.content.length; i++) {
      const contentName = newShowromData.content[i].name;
      for (let j = 0; j < newShowromData.content[i].content.length; j++) {
        if (i !== rowIndex) {
          innerContent.push({
            image: newShowromData.content[i].content[j].image,
            mainHeading: newShowromData.content[i].content[j].mainHeading,
            presentationId: newShowromData.content[i].content[j].presentationId,
            subHeading: newShowromData.content[i].content[j].subHeading,
            imageUrl: newShowromData.content[i].content[j].imageUrl,
          });
        } else {
          if (newShowromData.content[i].content[j].presentationId !== presId) {
            innerContent.push({
              image: newShowromData.content[i].content[j].image,
              mainHeading: newShowromData.content[i].content[j].mainHeading,
              presentationId:
                newShowromData.content[i].content[j].presentationId,
              subHeading: newShowromData.content[i].content[j].subHeading,
              imageUrl: newShowromData.content[i].content[j].imageUrl,
            });
          }
        }
      }
      arrContent.push({ name: contentName, content: innerContent });
      innerContent = [];
    }
    if (!createShowroom) {
      EditShowroomMutation(
        editShowroomId,
        { ...showroom, content: arrContent },
        response => {
          responseHandler(response, t("showroom-updated"));
        }
      );
    } else {
      deletePresentationOnCreation(arrContent);
      showSuccessToast(t("showroom-updated"));
    }
  };

  const handleSave = (images) => {
    let newShowroomData = { ...showroomData };
    let contentArr = [];
    let presObj = {};
    let row = {};
    let rows = [...newShowroomData.content];
    const uploads = [];
    for (let index = 0; index < images.length; index++) {
      const image = images[index];
      const { rowIndex } = image.itemData;
      const imgId = image.id;

      row = { ...newShowroomData.content[parseInt(rowIndex.split("&")[0])] };
      contentArr = [...row.content];
      for (let j = 0; j < contentArr.length; j++) {
        const presentationId = contentArr[j].presentationId;
        if (contentArr[j].presentationId.toString() === imgId.toString()) {
          presObj = { ...contentArr[j] };
          if (presObj.files) delete presObj.files;        
          presObj.imageUrl = "";
          contentArr[j] = presObj;
          row.content = contentArr;
          rows[parseInt(rowIndex.split("&")[0])] = row;
          newShowroomData.content = rows;
        }
        if (presentationId && presentationId === imgId && image?.blob) {
          uploads.push({ file: image?.blob, presentationId });
        }
      }
    }
    const {
      _id: editShowroomId,
      id,
      updatedAt,
      templateId,
      accountId,
      createdAt,
      creator,
      __typename,
      ...edited
    } = newShowroomData;
    if (uploads.length) {
      setContext({
        ...context,
        uploading: {
          ...edited,
          isUpload: true,
          uploads: uploads
        },
      });
    }
  };

  const thumbnailUploader = async () => {
    if (!imageChangesQueue.length) {
      return false;
    } else {
      handleSave(imageChangesQueue);
    }
  };

  const thumbnailDeleter = async (coverUrl, modalData) => {
    const imgId = modalData?.data?.id;
    const { rowIndex } = modalData?.data;
    let newShowroomData = { ...showroomData };
    let contentArr = [];
    let presObj = {};
    let row = {};
    let rows = [...newShowroomData.content];
    for (let i = 0; i < rows.length; i++) {
      row = { ...newShowroomData.content[i] };
      contentArr = [...row.content];
      for (let j = 0; j < contentArr.length; j++) {
        presObj = { ...contentArr[j] };
        delete presObj.files;
        if (contentArr[j].presentationId.toString() === imgId.toString()) {
          if (i === parseInt(rowIndex.split("&")[0])) {
            presObj.imageUrl = contentArr[j].image;
          }
        }
        contentArr[j] = { ...presObj };
        row.content = contentArr;
        rows[i] = row;
      }
    }
    newShowroomData.content = rows;
    const {
      _id: editShowroomId,
      id,
      updatedAt,
      templateId,
      accountId,
      createdAt,
      creator,
      __typename,
      ...edited
    } = newShowroomData;
    EditShowroomMutation(editShowroomId, edited, response => {
      if (response.editShowroom.id) {
        setOpenModal({ open: false });
        responseHandler(response, t("showroom-updated"));
      }
    }); // no need for callback
    await deleteFilesByUrls([coverUrl]);
  };

  const updateThumnails = () => {
    setIsUpdating(true);
    thumbnailUploader();
  };

  useEffect(() => {
    document
      .querySelector(".save-showroom")
      ?.addEventListener("click", updateThumnails);
    return () => {
      document
        .querySelector(".save-showroom")
        ?.removeEventListener("click", updateThumnails);
    };
  }, [imageChangesQueue]);

  const sliderAndFullScreen = ({ presentation, presentationId }, index) => {
    setCurrentClickedPresentationId(presentationId);
    setPresFiles(getPresentationFiles(presentationId) || presentation?.files);
    setSliderPopUp(true);
    if (isMicrosite === true) {
      setViewingItem(true);
    }
    if(templateId !== 14 || templateId !== 15){
      document.body.requestFullscreen();
    }
    setCurrentPresentationIndex(index);
  };  

  const findNextPresentationId = (currentPresentationId, data) => {
    const currentIndex = data?.content.findIndex(
      item => item?.presentationId === currentPresentationId
    );

    if (currentIndex !== -1 && currentIndex < data?.content.length - 1) {
      return data?.content[currentIndex + 1]?.presentationId;
    } else {
      return null;
    }
  };
  const nextPresentationId = findNextPresentationId(
    currentClickedPresentationId,
    data
  );
  const [showContentTooltip, setShowContentTooltip] = useState(false)
    const { data:presentations } = useQuery(
      PresentationsQuery,
      {
        presentationId: currentClickedPresentationId || "",
      },
      {
        fetchPolicy: "store-and-network",
        skip: !nextPresentationId,
      }
      );
      const handleNextPres = () => {
        if (nextPresentationId) {
          setCurrentClickedPresentationId(nextPresentationId);
          setPresFiles(getPresentationFiles(nextPresentationId) || presentations?.presentation?.files);
        }
      };
      const flattenedContent = context?.showroomDetails?.content?.flatMap(res => res?.content) || [];
      const updatedContext = {
        ...context,
        showroomDetails: {
          ...context?.showroomDetails,
          content: [
            {
              ...context?.showroomDetails?.content?.[0],
              content: flattenedContent
            }
          ]
        }
      };
  return (
    <>
      <div className={`containerSlider`}>
        {!isPreview && (
          <div className="plusParent">
            {showContentTooltip && <p className="content-slide">{t("Add content")}</p>}
            <button
              onClick={() => handleAdd()}
              className="add-img-circle-top"
              onMouseEnter={() => setShowContentTooltip(true)}
              onMouseLeave={() => setShowContentTooltip(false)}
            >
              +
            </button>
          </div>
        )}
        <div
          className={`cards expandable${className} 
        ${itemsClass} ${
            templateId >= 5 && templateId <= 8 && `expandable-list`
          } `}
        >
          {show ? (
            <AiFillMinusCircle
              onClick={() => toggleShow(!show)}
              size={35}
              fill="#343434"
              className="minus"
            />
          ) : null}
          <>
            {data?.content?.map((d, i) => {
              return (
                <li
                  className={`slider-item single-show-item ${
                    -x / 100 === i ? "visibilityItem" : "hiddenItem"
                  }`}
                key={i}
                style={{
                  transform: dimensions?.width<=1024 ? `translateY(${x}%)`: templateId!==16  ? `translateX(${x}%)` :   `translateY(${x}%)`,
                  marginRight: "20px",
                  minWidth: `${100 / slideNumber - 1.3}%`,
                  maxWidth: `${100 / slideNumber - 1.3}%`,
                }}
              >
                <TemplateCard
                  key={key + String(i)}
                  index={index}
                  onChangeEditor={onChangeEditor}
                  title={d.subHeading}
                  disabled={isPreview}
                  {...d}
                  showroomSubContent={d}
                  rowIndex={rowIndex + "&" + String(i)}
                  onClickfn={sliderAndFullScreen}
                  presentationId={d.presentationId}
                  tooltip={false}
                  cardIndex={i}
                  templateId={templateId}
                  elementData={d.elementsOfSlider}
                  list={list}
                  isMicrosite={isMicrosite}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  deleteState={deleteState}
                  handleDeletePresentation={handleDeletePresentation}
                  setRowKey={setRowKey}
                  isPreview={isPreview}
                />
              </li>
            );
          })}
          {emptyRooms>0&&!isPreview&&
          <li
          className={`slider-item ${-x / 100 === data?.content?.length ? "visibilityItem" : "hiddenItem"
            }`}
          style={{
            transform: `translateX(${x}%)`,
            marginRight: "20px",
            minWidth: `${100 / slideNumber - 1.3}%`,
            maxWidth: `${100 / slideNumber - 1.3}%`,
          }}
        >
          <TemplateCard
                  key={key + String(data?.content?.length)}
                  onChangeEditor={onChangeEditor}
                  title={"<p>No Content inside this row</p>"}
                  description={"<p>No Content inside this row</p>"}
                  disabled={true}
                  rowIndex={rowIndex + "&" + String(data?.content?.length)}
                  onClickfn={sliderAndFullScreen}
                  tooltip={false}
                  cardIndex={data?.content?.length}
                  templateId={templateId}
                  isMicrosite={isMicrosite}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  deleteState={deleteState}
                  noContent={true}
                  handleAdd={handleAdd}
                  setRowKey={setRowKey}
                />
              </li>
          }
          </>
        </div>
      </div>
        {x === 0 ? null : (
          <button
            onClick={() => {
              goLeft();
            }}
            id="left"
            className={`itemsRow-${slideNumber}`}
          >
            <AiOutlineLeft size={17} fill="#fff" />
          </button>
        )}
        {x === -Math.floor(itemsLength - slideNumber) * 100 ||
        itemsLength <= slideNumber ? null : (
          <button
            onClick={() => {
              goRight();
            }}
            id="right"
            className={`itemsRow-${slideNumber}`}
          >
            <AiOutlineRight size={17} fill="#fff" />
          </button>
        )}
      {sliderPopUp ? (
        <Modal animation={false} show={sliderPopUp} hide={!sliderPopUp}>
          <PresentationSlider
            presentationFiles={
              getPresentationFiles(currentClickedPresentationId) || presFiles
            }
            setSliderPopUp={setSliderPopUp}
            setViewingItem={setViewingItem}
            isMicrosite={isMicrosite}
            showroomId={showroomData._id}
            nextPres={handleNextPres}
            presentationId={currentClickedPresentationId}
            nextPresentationId={nextPresentationId}
            bgImage={updatedContext?.showroomDetails?.backgroundImage}
          />
        </Modal>
      ) : null}
      {openModal.open && (
        <Modal animation={false} show={openModal.open} hide={!openModal.open}>
          <UploadModal
            closeModal={setOpenModal}
            modal={openModal}
            image={imageChangesQueue}
            setImage={setImageChangesQueue}
            title= {t("add-new-cover")}
            thumbnailDeleter={thumbnailDeleter}
            loading={false}
          />
        </Modal>
      )}
      {(templateId===16 || dimensions?.width<=1024) &&
      <div className="backdrop-card">
      {
      (dimensions?.width<=1024 ?
      updatedContext?.showroomDetails?.content?.[0]?.content?.[cardIndex]?.imageUrl?.includes("mp4" || "mov" || "wmv" || "ogg" || "webm" || "mpeg" || "quicktime")  :
      context?.showroomDetails?.content?.[0]?.content?.[cardIndex]?.imageUrl?.includes("mp4" || "mov" || "wmv" || "ogg" || "webm" || "mpeg" || "quicktime")) 
      ?
      <div className="card-backdrop-video">
         <VideoPlayer
      src={`${context?.showroomDetails?.content?.[0]?.content?.[cardIndex]?.imageUrl?.replace(/\s/g, "%20")}`}
       />
        </div>
      :
        <DocFrame image={getThumbnail(
          dimensions?.width<=1024 ?
          updatedContext?.showroomDetails?.content?.[0]?.content?.[cardIndex]?.imageUrl:
          context?.showroomDetails?.content?.[0]?.content?.[cardIndex]?.imageUrl
        )} /> }
      </div>
      }
    </>
  );
}
