import "@learnio-as/video-player/dist/index.css";
import pdfthumbnail from "assets/images/pdfthumbnail.png";
import pptthumbnail from "assets/images/pptthumbnail.png";
import wordthumbnail from "assets/images/wordthumbnail.png";
import excelthumbnail from "assets/images/excelthumbnail.png";
import txtthumbnail from "assets/images/txt.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { STATIC_URL, OFFICE_URL } from "constants/env";
import { getFileExtension } from "helpers/fileHandler";
import checkTypes from "helpers/checkTypes";
const urlPrefix = {
  officedoc: OFFICE_URL,
  googlepdf: STATIC_URL,
  link: "",
  video: STATIC_URL,
  iframe: STATIC_URL,
};
export default function Thumbnail({ file, fileType }) {
  const linkfileType = getFileExtension(file?.fileType);
  const getStaticUrl = type => urlPrefix[type] || "";
  return (
    <div
      aria-hidden="true"
      role={"button"}
      className={`thumbnail-container thumbnail-${fileType}`}
    >
      {file?.loading && <img src="/assets/img/loader-1.gif" alt="loading..." />}
      {file?.fileUrl.includes("spreadsheets") ||
      fileType === "application/vnd.ms-excel" ? (
        <LazyLoadImage effect="blur" src={excelthumbnail} alt="excel" />
      ) : file?.fileUrl.includes("document") ||
        fileType === "application/msword" ? (
        <LazyLoadImage effect="blur" src={wordthumbnail} alt="word" />
      ) : file?.fileUrl.includes("ppt") ||
        file?.fileUrl.includes("google.com/presentation") ||
        fileType === "application/vnd.ms-powerpoint" ? (
        <LazyLoadImage effect="blur" src={pptthumbnail} alt="powerpoint" />
      ) : file?.fileUrl.includes(".txt") || fileType==="text/plain" ?
      <LazyLoadImage effect="blur" src={txtthumbnail} alt="text" /> :
      file?.fileUrl?.toLowerCase().includes("pdf") || fileType === "application/pdf" ? (
        <LazyLoadImage effect="blur" src={pdfthumbnail} alt="pdf" />
      ) : fileType?.includes("video") ? (
        <LazyLoadImage
          effect="blur"
          src={
            "https://cdn.cf.masterwizr.com/videothumb_genqsx.jpg"
          }
          alt="pdf"
        />
      ) : checkTypes(file?.fileUrl) === "audio" ? (
        <LazyLoadImage
          effect="blur"
          src={
            "https://cdn.cf.masterwizr.com/Frame_41939_sn0urm.png"
          }
          alt="pdf"
        />
      ) : (
        <iframe
          title="iframe"
          loading="lazy"
          role="presentation"
          className={String(linkfileType || "")}
          src={`${getStaticUrl(linkfileType)}${file.fileUrl}#toolbar=0`}
        />
      )}
    </div>
  );
}
