/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type newCoverpage = {|
  name: string,
  mainHeading: string,
  details: string,
  templateId: number,
  owner?: ?InputOwner,
  thumbnail?: ?any,
  tags?: ?$ReadOnlyArray<?string>,
  showroomIds?: ?$ReadOnlyArray<string>,
  showroomLink: string,
  accountId: string,
  portalId: string,
  templateStyle?: ?InputTemplateStyle,
|};
export type InputOwner = {|
  name: string,
  avatarUrl: string,
  job: string,
  shortBio: string,
  quote: string,
  skills: string,
  services: string,
  engagements: string,
|};
export type InputTemplateStyle = {|
  cardPosition?: ?string,
  BackgroundBlur?: ?string,
|};
export type CreateCoverpageMutationVariables = {|
  coverpage?: ?newCoverpage
|};
export type CreateCoverpageMutationResponse = {|
  +createCoverpage: ?({|
    +__typename: "Coverpage",
    +_id: string,
    +id: string,
    +name: string,
    +portalId: string,
    +mainHeading: string,
    +details: string,
    +templateId: number,
    +thumbnail: ?string,
    +tags: ?$ReadOnlyArray<?string>,
    +showroomLink: string,
    +creator: string,
    +accountId: string,
    +updatedAt: ?any,
    +createdAt: any,
    +showroomIds: ?$ReadOnlyArray<string>,
  |} | {|
    +__typename: "Response",
    +__typename: "Response",
    +message: string,
    +status: number,
    +success: boolean,
    +token: ?string,
    +hash: ?string,
    +userId: ?string,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |})
|};
export type CreateCoverpageMutation = {|
  variables: CreateCoverpageMutationVariables,
  response: CreateCoverpageMutationResponse,
|};
*/


/*
mutation CreateCoverpageMutation(
  $coverpage: newCoverpage
) {
  createCoverpage(coverpage: $coverpage) {
    __typename
    ... on Coverpage {
      _id
      id
      name
      portalId
      mainHeading
      details
      templateId
      thumbnail
      tags
      showroomLink
      creator
      accountId
      updatedAt
      createdAt
      showroomIds
    }
    ... on Response {
      __typename
      message
      status
      success
      token
      hash
      userId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "coverpage"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "coverpage",
        "variableName": "coverpage"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createCoverpage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "portalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mainHeading",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "templateId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showroomLink",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showroomIds",
            "storageKey": null
          }
        ],
        "type": "Coverpage",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hash",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "type": "Response",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCoverpageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCoverpageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d771143005c3174e1fa3da3da99bed4d",
    "id": null,
    "metadata": {},
    "name": "CreateCoverpageMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCoverpageMutation(\n  $coverpage: newCoverpage\n) {\n  createCoverpage(coverpage: $coverpage) {\n    __typename\n    ... on Coverpage {\n      _id\n      id\n      name\n      portalId\n      mainHeading\n      details\n      templateId\n      thumbnail\n      tags\n      showroomLink\n      creator\n      accountId\n      updatedAt\n      createdAt\n      showroomIds\n    }\n    ... on Response {\n      __typename\n      message\n      status\n      success\n      token\n      hash\n      userId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '737805b86bee9fd5033449c3532f4a22';

module.exports = node;
