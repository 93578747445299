// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";
import { getCachedMicrosites_Id, getCachedShowrooms_Id } from "../utils/index.js";

// 2
// deleteCoverpage(id: ID!): Response
const mutation = graphql`
  mutation DeleteShowroomMutation($deleteShowroomId: ID!) {
    deleteShowroom(id: $deleteShowroomId) {
      ... on Showroom {
        id
        portalId
        _id
      }

      ... on Response {
        message
        status
        success
        token
        hash
        signedUrl
        userId
        shared
        __typename

      }
    }
  }
`;

const deleteFromCache = (store, deleteShowroomId) => {
  const portalId = store.getRootField("deleteShowroom").getValue("portalId");
  const cachedShowroomsId = getCachedShowrooms_Id(`portalId:"${portalId}"`);
  let cachedShowrooms = store
    .get(cachedShowroomsId)
    ?.getLinkedRecords("showrooms");

  if (cachedShowrooms) {
    cachedShowrooms = cachedShowrooms.filter(
      item => item?.getValue("id") !== deleteShowroomId
    );

    store
      .get(cachedShowroomsId)
      .setLinkedRecords(cachedShowrooms, "showrooms");
  }
};

const deleteCorrespondingMicrosite = (store, deleteShowroomId) => {
  const portalId = store.getRootField("deleteShowroom").getValue("portalId");
  const cachedMicrositesId = getCachedMicrosites_Id(`portalId:"${portalId}"`);
  let cachedMicrosites = store
    .get(cachedMicrositesId)
    ?.getLinkedRecords("microsites");

  if (cachedMicrosites) {
    let affectedCachedMicrosites = cachedMicrosites.filter(item =>
      item.getValue("showroomsIds").includes(deleteShowroomId)
    );
    let unAffectedCachedMicrosites = cachedMicrosites.filter(
      item => !item.getValue("showroomsIds").includes(deleteShowroomId)
    );

    affectedCachedMicrosites = affectedCachedMicrosites.filter(item => {
      let showroomIds = item.getValue("showroomsIds");
      showroomIds = showroomIds.filter(id => id !== deleteShowroomId);
      if (showroomIds.length === 0) return false;
      item.setValue(showroomIds, "showroomsIds");
      return item;
    });
    const cachedMicrositesId = getCachedMicrosites_Id(`portalId:"${portalId}"`);
    store
      .get(cachedMicrositesId)
      .setLinkedRecords(
        [...affectedCachedMicrosites, ...unAffectedCachedMicrosites],
        "microsites"
      );
  }
};

// this function will mark the corresponding microsite as old, and relay then wi;; make a request to the server

const staleCorrespondingMicrosite = (store, deleteShowroomId) => {
  const portalId = store.getRootField("deleteShowroom").getValue("portalId");
  const cachedMicrositesId = getCachedMicrosites_Id(`portalId:"${portalId}"`);
  let cachedMicrosites = store
    .get(cachedMicrositesId)
    ?.getLinkedRecords("microsites");

  if (cachedMicrosites) {
    const affectedCachedMicrosites = cachedMicrosites.filter(item =>
      item.getValue("showroomsIds").includes(deleteShowroomId)
    );

    affectedCachedMicrosites.forEach(item => {
      item.invalidateRecord();
    });
  }
};

export default (deleteShowroomId, callback) => {
  // 4
  const variables = {
    deleteShowroomId,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      // deleteFromCache(store, deleteShowroomId);
      // deleteCorrespondingMicrosite(store, deleteShowroomId);
      // Temporary Acces to Store In Consol for testing.
      window.store = store;
    },
  });
}; // 3
