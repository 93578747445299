// import { showErrorToast, showSuccessToast, showWarnToast } from '@learnio-as/toast';

function checkCharLimit(ed) {
    const allowedKeys = [8, 37, 38, 39, 40, 46];
    ed.on('init', function(e) {
        const elements =document.getElementsByClassName('mce-content-body')
        const requiredElement = elements[0];
        eventFire(requiredElement, 'click');
        requiredElement.click();
    })
    ed.on('keydown', function (e) {
        if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
        if (ed.getContent().length + 1 > this.settings?.max_chars) {
            e.preventDefault();
            e.stopPropagation();
            const toast = document.querySelector('.Toastify__toast--warning')
            // !toast && showWarnToast(`Reached character limit!`)
            return false;
        }
        return true;
    });
}
const eventFire = (el, etype) =>{
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

const toolBar = (maxChars, cb = null) => ({
  menubar: false,
  inline: true,
  plugins: ["lists", "link"],
  toolbar:
    "undo redo | bold italic underline | alignleft aligncenter alignright alignfull | fontselect | fontsizeselect | link |",
  link_target_list: false,
  fontsize_formats:
    "8px 10px 12px 14px 18px 24px 36px 38px 40px 45px 50px 60px",
  valid_elements: "p[style],strong,em,span[style],a[href],ul,li,div[style]",
  valid_styles: {
    "*": "font-size,font-family,color,text-decoration,text-align,font-weight,font-size,padding-bottom,margin-bottom",
    ul: "list-style-type",
  },
  content_css: "//www.tiny.cloud/css/codepen.min.css",
  max_chars: maxChars, // max. allowed chars
  setup: function (editor) {
    checkCharLimit(editor);
    // This prevents the blur event from hiding the toolbar
    editor.on("blur", function () {
      return false;
    });
  },
  font_formats:
    "Raleway light=Raleway; Segoe UI Light=Segoe UI Light; Andale Mono=andale mono v1; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal-regular; Times New Roman=times new roman; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
  content_style:
    "@import url(https://db.onlinewebfonts.com/c/663974c9fe3ba55b228724fd4d4e445f?family=Andale+Mono+V1); @import url(https://db.onlinewebfonts.com/c/2abddb216b6ffd1c0f62697728f7cf94?family=Terminal-Regular); @import url(https://db.onlinewebfonts.com/c/32441506567156636049eb850b53f02a?family=Times+New+Roman); @import url(//db.onlinewebfonts.com/c/5076583fa2a14cd2cf3634ff59a1138b?family=Segoe+UI+Light); @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');",
  init_instance_callback: function (editor) {
    // This will trick the editor into thinking it was focused
    // without actually focusing it (causing the toolbar to appear)
    editor.fire("focus");
  },
});

const initEditor = {
  init_instance_callback: function (editor) {
    // This will trick the editor into thinking it was focused
    // without actually focusing it (causing the toolbar to appear)
    editor.fire('focus');

  }
}

const createInit = selector => charLimit => ({selector, ...toolBar(charLimit)})
const createEditorInit = selector => charLimit => ({selector, ...toolBar(charLimit),
  ...initEditor

})

export const mainHeadingInit = createEditorInit('#main-heading')
export const mainSubHeadingInit = createInit('#main-sub-heading')

