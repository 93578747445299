import { showErrorToast } from "@learnio-as/toast";
import CoverpageDetailedPage from "components/ui/organisms/CoverpageDetailedPage/CoverpageDetailedPage";

import { mapper } from "containers/Templates/showrooms/renderShowroom";
import SharedItemQuery from "graphql/queries/GetSharedItemBySlugQuery";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "relay-hooks";
import CopyInput from "../../atoms/Input/CopyInput";
import CustomeModel from "../../atoms/Modals/CustomeModel";
import RoomSlider from "../RoomSlider";

function SharedContentPage() {
  const [securityPass, setSecurityPass] = useState("");
  const { slug } = useParams();
  const isProtected = slug.split("-")[2] === "protected";
  const [showModel, setShowModel] = useState(isProtected);
  const [getContent, setGetContent] = useState(!isProtected);
  const [content, setContent] = useState(null);

  const [passwordType, setPasswordType] = useState("password");
  const handlePasswordChange = evnt => {
    setSecurityPass(evnt.target.value);
  };

  const onModelSubmit = () => {
    if (securityPass.length === 0) return;
    setGetContent(true);
  };
  const coverpageType = "Coverpage";
  const showroomType = "Showroom";
  const micrositeType = "Microsite";

  const { data, isLoading } = useQuery(
    SharedItemQuery,
    { slug, securityPass },
    {
      fetchPolicy: "store-and-network",
      skip: !getContent,
    }
  );

  const hydrateContentResultToTemplateId = content => {
    let result = content.map(item => {
      const {
        coverpageTemplateId,
        micrositeTemplateId,
        showroomTemplateId,
        ...rest
      } = item;
      const {
        coverpageAccountId,
        micrositeAcountId,
        showroomAccountId,
        ...rest2
      } = item;
      return {
        ...rest,
        ...rest2,
        templateId:
          coverpageTemplateId || micrositeTemplateId || showroomTemplateId,
        accountId: coverpageAccountId || micrositeAcountId || showroomAccountId,
      };
    });

    return result;
  };

  const getShowroomComponent = dataItem => {
    let Template;

    const templateId = dataItem?.templateId;

    Template = mapper[templateId];
    let data = { showroom: { ...dataItem } };
    return (
      <>
        <Template.Component templateId={templateId} showroomData={data} />
      </>
    );
  };
  const getCoverpageComponent = dataItem => {
    let data = { coverpage: { ...dataItem } };

    return <CoverpageDetailedPage data={data} id={data.coverpage.templateId} />;
  };
  const getMicrositeComponent = sharedData => {
    const micrositeArray = sharedData.filter(
      item => item.__typename === micrositeType
    );
    const coverpageArray = sharedData.filter(
      item => item.__typename === coverpageType
    );
    const showrooms = sharedData.filter(
      item => item.__typename === showroomType
    );

    let coverpageData;
    if (coverpageArray.length > 0) {
      coverpageData = { coverpage: { ...coverpageArray[0] } };
    }

    const queryMicrosite = { microsite: { microsite: micrositeArray[0] } };
    const showroomsIds = showrooms.map(showroom => showroom._id);

    return (
      <RoomSlider
        showroomsIds={showroomsIds}
        queryMicrosite={queryMicrosite}
        selectedShowRooms={showrooms}
        sharedCoverpageData={coverpageData}
        sharedCoverpageId={coverpageData?.coverpage?.templateId}
        editing={true}
        backgroundImage={sharedData[0]?.backgroundImage}
      />
    );
  };

  const getComponent = sharedData => {
    if (!sharedData?.content) return null;
    let { content } = sharedData;
    content = hydrateContentResultToTemplateId(content);
    const isMicrosite =
      content.find(item => item.__typename === micrositeType) !== undefined;

    if (isMicrosite) return getMicrositeComponent(content);
    const dataItem = content[0];
    const { __typename: itemType } = dataItem;
    return itemType === coverpageType
      ? getCoverpageComponent(dataItem)
      : getShowroomComponent(dataItem);
  };

  useEffect(() => {
    if (data) {
      setGetContent(false);
      const { message } = data.getSharedStudioItemBySlug;
      if (message) showErrorToast(message);
      else setShowModel(false);
      setContent(data.getSharedStudioItemBySlug);
    }
  }, [slug, data]);

  if (isLoading) return null;
  return (
    <>
      {showModel && (
        <>
          <CustomeModel showSubmitButton handleSubmit={onModelSubmit}>
            <p>SecurityPass</p>
            <CopyInput
              value={securityPass}
              onChange={handlePasswordChange}
              type={passwordType}
              setPasswordType={setPasswordType}
              isSharedpassword
            />
            {content?.message && (
              <p style={{ color: "rgb(255 102 102)" }}>{content?.message}</p>
            )}
          </CustomeModel>
        </>
      )}
      {!showModel && <>{getComponent(content)}</>}
    </>
  );
}

export default SharedContentPage;
