import PropTypes from "prop-types";
import "./DesignEditor.scss";
import React, { useState, useEffect } from "react";
import Input from "../Input/input";
import Sidebar from "./sidebar";
import Toggle from "./toggle";
import { Title, ShareButton } from "./styles";
import CheckoutShowrooms from "./checkoutShowrooms";
import Tags from "../../../../containers/Tags";
import { getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import ImgModal from "../ImgModal/ImgModal";
import IsLoading from "components/ui/atoms/IsLoading";
import WarnModal from "containers/Modals/Warn";
import { showErrorToast } from "@learnio-as/toast";
import { useTour } from "@reactour/tour";
import { useTranslation } from "react-i18next";

function DesignEditor({
  children,
  title,
  onChangeMetaData,
  handleSaveCoverpage,
  isPreview,
  isCheckoutShowrooms,
  closeCheckoutShowrooms,
  handleChangeTag,
  tag,
  tags,
  handleKeyDown,
  handleRemoveTag,
  extraClassName,
  onClickPreview,
  alreadyExists,
  handleEditCoverpage,
  sharing,
  onChangeEditor,
  imageChangesQueue,
  checkRequiredFields = () => true,
  isLoading,
  value = "",
  showPopUp = false,
  setShowPopUp,
  mainHeading,
  handleNameInputChange,
  inputValue
}) {
  const [popup, setPopup] = useState(false);
  const [changes, setChanges] = useState(-2);
  const [nameInputValue, setNameInputValue] = useState(inputValue);
  const { t } = useTranslation();

  useEffect(() => {
    const stringWithoutFirstThree = mainHeading && mainHeading.length >= 3 ? mainHeading.substring(3) : "";
    const mainHeadingWithoutTags = stringWithoutFirstThree.slice(0, -4);
    setNameInputValue(mainHeadingWithoutTags);
  }, [mainHeading]);

  const handleChange = (e) => {
    const valueInput = e.target.value;
      setNameInputValue(valueInput);
      handleNameInputChange(e);
  };
  
  useEffect(
    () => {
      if(document.querySelector('.maincontent') || document.querySelector('.children-section') || imageChangesQueue.length > 0 ) {
        setChanges(changes + 1);
      }
    },
    [ onChangeEditor, imageChangesQueue ],
  );

  const hidePopup = () => {
    setPopup(false);
  };

  const [extraClass, setExtraClass] = useState("close");
  const portalRole = getClientData(
    useQuery,
    clientOnly,
    {},
    "Portal"
  )?.portalRole;

  useEffect(()=> {
    if(showPopUp){
      setPopup(false)
    } 
  }, [showPopUp])

  const handleHitTagPrompt = () => {
    setPopup(true)
    setShowPopUp(false)
  }
  const handleCharacters = e => {
    const value = e.target.value;
    if(value.length >= 28){
      showErrorToast(t("name-is-not-allowed"));
    }
  };
  const {currentStep,setCurrentStep,setIsOpen} = useTour();
  const modals = getClientData(useQuery, clientOnly, {}, "Modal");
  return (
    <div
      className={`design-editor ${extraClassName} ${
        isPreview || sharing ? "preview-view" : ""
        }`}
    >
      {isCheckoutShowrooms && (
        <CheckoutShowrooms closeCheckoutShowrooms={closeCheckoutShowrooms} />
      )}
      <div className="shared-designeditor">
        <div className="children-section">
          {children}
        </div>
      </div>
      <div className="finish-button">
        {!portalRole?.includes("VIEWER") && (
          <div className="bottombuttons-template">
            {!isPreview && (
              <>
                <button
                  onClick={() => {onClickPreview();
                    modals?.tour && setCurrentStep(currentStep+1);
                  }}
                  className="finish previewbtn"
                >
                  {t("Preview")}
                </button>
                {!alreadyExists ? (
                  <button
                    className="finish separate-button"
                    onClick={() => {setPopup(checkRequiredFields('editor'));setIsOpen(false);}}
                  >
                    {t("Finish")}
                  </button>
                ) : (
                  <button
                  className={changes >= 0 ? "finish separate-button save-showroom" : "separate-button disabled-btn save-showroom"}
                    onClick={() => changes >= 0 && handleEditCoverpage()}
                    disabled={changes < 0}
                  >
                    {t("Save")}
                  </button>
                )}
              </>
            )}
          </div>
        )}
        {isLoading? <IsLoading isContent/> :<ImgModal 
        description={t("your-room-is-soon-ready")}
        title={t("saving-name-tags")} state={popup} hide={hidePopup} extraClass="savemodal" width="50%" maxWidth="600px">
          <div className="center-content">
            {/* <p className="title">{`${
              title === "Cover Page" ? "Create" : ""
            } Your ${title || "Show Room is almost done"}`}</p> */}
            <div
            style={{marginTop:30}}
              className={`input-flex ${
                title === "Cover Page" && "inputWithoutname"
                }`}
            >
              <Title>{t("Name")}</Title>
                <Input
                type="text"
                name="link"
                autoFocus
                onChange={inputValue ? handleChange : onChangeMetaData("name")}
                required={true}
                value={inputValue ? nameInputValue : value}
                onKeyDown={handleCharacters}
                nrlength="28"
                />
            </div>
            <div className="input-flex">

              <p>{t("Tags")}</p>
              <Tags
                tag={tag}
                tags={tags}
                handleRemoveTag={handleRemoveTag}
                handleChangeTag={handleChangeTag}
                handleKeyDown={handleKeyDown}
              />
            </div>
            <span className="spantxt">{title} {t("to-add-tags-please-press-enter")}</span>
            <div>
            </div>
            <div className="buttons">
              <button onClick={hidePopup}>
                {t("Cancel")}
              </button>
              <button
                className="done-button"
                onClick={() => {
                  if(checkRequiredFields('popup')){
                    handleSaveCoverpage();
                  }
                }}
              >
                {t("Done")}
              </button>
            </div>
          </div>
        </ImgModal>
        }
      {showPopUp && <WarnModal handleHitTagPrompt={handleHitTagPrompt}/>}
      </div>
    </div>
  );
}
export default DesignEditor;
DesignEditor.propTypes = {
  children: PropTypes.element,
  image: PropTypes.array,
  overlay: PropTypes.array,
  createShowroom: PropTypes.func,
  onChangeMetaData: PropTypes.func,
  extraClassName: PropTypes.string,
  sharing: PropTypes.bool,
  tag: PropTypes.string,
  showPopUp: PropTypes.bool,
  setShowPopUp: PropTypes.func
};

DesignEditor.defaultProps = {
  createShowroom: () => {},
  onChangeMetaData: () => {},
};
