// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";
import { getCachedMicrosites_Id } from "graphql/utils/index.js";

// 2
const mutation = graphql`
  mutation EditMicrositeMutation($microsite: editMicrosite) {
    editMicrosite(microsite: $microsite) {
      ... on Microsite {
        _id
        id
        name
        showroomsIds
        backgroundImage
        coverpageId
        templateId
        thumbnailUrl
        portalId
        creator
        accountId
        createdAt
        updatedAt
      }
      ... on Response {
        message
        status
        success
        token
        shared
        hash
        userId
        __typename
      }
    }
  }
`;

export default (microsite, callback) => {
  // needed for already created microsites
  const sanitizedMicrosite = {
    ...microsite,
    backgroundImage: microsite?.backgroundImage || null
  };

  const variables = {
    microsite: sanitizedMicrosite,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      const rootField = store.getRootField("editMicrosite");
      if (rootField) {
        window.store = store;
      }
    },
  });
};