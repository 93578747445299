import graphql from "babel-plugin-relay/macro";
import environment from "graphql/environment/index.js";
import { commitMutation } from "relay-runtime";

const mutation = graphql`
  mutation CreateShareItemMutation($values: sharedStudioItemInput!) {
    createShareStudioItem(shareContent: $values) {
      accountId
      creator
      itemId
      slug
      itemId
      itemName
      __typename
    }
  }
`;

const createShareItemMutation = (values, callback) => {
  const variables = { values: { ...values } };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: response => {
      const { createShareStudioItem } = response;
      callback(createShareStudioItem);
    },
    onError: err => console.log(err),
  });
};

export default createShareItemMutation;
