import { getAuthData, encryptString } from "helpers/authHandler";

const getDynamicVar = (name, variables, getFile = false) => {
  if (variables[name]) {
    return { [name]: getFile ? variables[name] : [null] };
  }
  return {};
};

export default async function fetchGraphQL(text, variables) {
  const cookieAuthToken = getAuthData();
  const ACTIVE_PROFILE = encryptString(getAuthData("activeProfile"));
  const { isUpload, ...variablesObj } =
    variables?.input ||
    variables?.updates ||
    variables?.coverpage ||
    variables?.showroom ||
    variables;
  const apiUrl =
    `${process.env.REACT_APP_API}` || "http://localhost:4001/graphql";
  const Request = {
    method: "POST",
    headers: {
      Authorization: `bearer ${cookieAuthToken || ""}`,
      "Content-Type": "application/json",
      ActiveProfile: ACTIVE_PROFILE,
    },
    body: JSON.stringify({ query: text, variables }),
  };

  if (isUpload) {
    let dynamicVars = {};
    let Files = {};
    const uploadableMap = {};
    let key = 0;
    let filesVar = { ...variablesObj, ...dynamicVars };

    delete Request.headers["Content-Type"];
    const path =
      (variables.input && "variables.input.") ||
      (variables.showroom && "variables.showroom.") ||
      (variables.microsite && "variables.microsite.") ||
      (variables.coverpage && "variables.coverpage.") ||
      "variables.updates.";
    const formData = new FormData();
    ["thumbnail", "thumbnailUrl", "backgroundImage"].forEach(item => {
      if (item in variablesObj) {
        dynamicVars = {
          ...dynamicVars,
          ...getDynamicVar(item, variablesObj),
        };
        filesVar = { ...filesVar, ...dynamicVars };
        filesVar = (variables.input && { input: filesVar }) ||
          (variables.coverpage && { coverpage: filesVar }) ||
          (variables.microsite && { microsite: filesVar }) || {
            updates: filesVar,
          };
        const operations = { query: text, variables: filesVar };
        Files = { ...Files, ...getDynamicVar(item, variablesObj, true) };
        const fileKey = ++key;
        uploadableMap[fileKey] = [`${path}${item}`];
        formData.set("operations", JSON.stringify(operations));
        formData.set("map", JSON.stringify(uploadableMap));
        formData.append(fileKey, Files[item]);
      }
    });
    if (variables.showroom && variablesObj.uploads) {
      const operations = {
        query: text,
        variables: {
          showroom: {
            ...variablesObj,
            uploads: variablesObj.uploads.map(it => ({
              presentationId: it.presentationId,
              file: [null],
            })),
          },
        },
      };
      formData.set("operations", JSON.stringify(operations));
      variables.showroom.uploads.map((item, index) => {
        const { file } = item;
        const fileKey = ++key;
        uploadableMap[fileKey] = [`${path}uploads.${index}.file`];
        formData.set("map", JSON.stringify(uploadableMap));
        formData.append(fileKey, file);
      });
    }
    Request.body = formData;
    Request.headers["apollo-require-preflight"] = "true";
  }
  return fetch(apiUrl, Request).then(res => res.json());
}
