import { Magazine } from "@learnio-as/magazine";
import "@learnio-as/magazine/dist/index.css";
import React from "react";
import StickyNav from "containers/NavBar";
import SharedText from "components/ui/atoms/SharedText/SharedText";
import GetMagazinesByPortal from "graphql/queries/GetMagazinesByPortal";
import CreateMagazineMutation from "graphql/mutations/CreateMagazineMutation";
import EditMagazineMutation from "graphql/mutations/EditMagazineMutation";
import {
  mainHeadingInit,
  mainSubHeadingInit,
} from "components/ui/atoms/CoverPages/editorSettings";
import { getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import {
  AWS_KEY_ID,
  AWS_KEY,
  BUCKET,
  AWS_REGION,
  STATIC_URL,
} from "constants/env";
import DeleteMagazineMutation from "graphql/mutations/DeleteMagazineMutation";
import ShareableLink from "components/ui/atoms/ShareModal/shareableLink";
import Sharing from "components/ui/atoms/ShareModal/Sharing";
import SharedItemQuery from "graphql/queries/GetSharedItemBySlugQuery";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
const MagazinePage = () => {
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  let  locale = profile?.locale?.toLowerCase() || 'en';
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");

  return (
    <Magazine
      className={`magazine ${styles.magazine}`}
      useQuery={useQuery}
      GetMagazinesByPortal={GetMagazinesByPortal}
      CreateMagazineMutation={CreateMagazineMutation}
      DeleteMagazineMutation={DeleteMagazineMutation}
      EditMagazineMutation={EditMagazineMutation}
      SharedText={SharedText}
      mainHeadingInit={mainHeadingInit}
      mainSubHeadingInit={mainSubHeadingInit}
      StickyNav={StickyNav}
      portalId={portal?.portalId}
      accountId={portal?.portalInfo?.accountId}
      AWS_KEY_ID={AWS_KEY_ID}
      AWS_KEY={AWS_KEY}
      BUCKET={BUCKET}
      AWS_REGION={AWS_REGION}
      STATIC_URL={STATIC_URL}
      ShareableLink={ShareableLink}
      Sharing={Sharing}
      SharedItemQuery={SharedItemQuery}
      createPortal={createPortal}
      locale={locale}
    />
  );
};

export default MagazinePage;
