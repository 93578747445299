/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editShowroom = {|
  id: string,
  name?: ?string,
  mainHeading?: ?string,
  headerImage?: ?any,
  subHeading?: ?string,
  portalId?: ?string,
  accountId?: ?string,
  content?: ?$ReadOnlyArray<?InputContent>,
  uploads?: ?$ReadOnlyArray<uploadedFileInput>,
  tags?: ?$ReadOnlyArray<string>,
  zoomedLevel?: ?number,
  draggableX?: ?number,
  draggableY?: ?number,
  link?: ?string,
  coverpagesIds?: ?$ReadOnlyArray<string>,
  backgroundImage?: ?any,
|};
export type InputContent = {|
  name: string,
  content?: ?$ReadOnlyArray<?InputNestedContent>,
|};
export type InputNestedContent = {|
  image: string,
  mainHeading: string,
  presentationId: string,
  subHeading: string,
  imageUrl: string,
  files?: ?$ReadOnlyArray<?FileInputs>,
  presentation?: ?PresentationInput,
|};
export type FileInputs = {|
  name: string,
  fileType: string,
  thumbnailUrl?: ?string,
  coverUrl?: ?string,
  fileUrl: string,
  audioUrl?: ?string,
  links?: ?$ReadOnlyArray<?LinkInputs>,
  tags?: ?$ReadOnlyArray<?string>,
  position: number,
|};
export type LinkInputs = {|
  name: string,
  url: string,
|};
export type PresentationInput = {|
  coverUrl?: ?string,
  folderId: string,
  menuId: string,
  portalId: string,
  name?: ?string,
|};
export type uploadedFileInput = {|
  presentationId: string,
  file: any,
|};
export type EditShowroomMutationVariables = {|
  showroom?: ?editShowroom
|};
export type EditShowroomMutationResponse = {|
  +editShowroom: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +mainHeading?: string,
    +subHeading?: string,
    +headerImage?: ?string,
    +templateId?: number,
    +tags?: ?$ReadOnlyArray<string>,
    +link?: ?string,
    +creator?: string,
    +coverpagesIds?: ?$ReadOnlyArray<string>,
    +backgroundImage?: ?string,
    +accountId?: ?string,
    +createdAt?: any,
    +content?: ?$ReadOnlyArray<?{|
      +name: string,
      +content: ?$ReadOnlyArray<?{|
        +image: string,
        +mainHeading: string,
        +presentationId: string,
        +subHeading: string,
        +imageUrl: string,
      |}>,
    |}>,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +signedUrl?: ?string,
    +userId?: ?string,
    +shared?: ?string,
    +__typename: "Response",
  |}
|};
export type EditShowroomMutation = {|
  variables: EditShowroomMutationVariables,
  response: EditShowroomMutationResponse,
|};
*/


/*
mutation EditShowroomMutation(
  $showroom: editShowroom
) {
  editShowroom(showroom: $showroom) {
    __typename
    ... on Showroom {
      _id
      id
      name
      mainHeading
      subHeading
      headerImage
      templateId
      tags
      link
      creator
      coverpagesIds
      backgroundImage
      accountId
      createdAt
      content {
        name
        content {
          image
          mainHeading
          presentationId
          subHeading
          imageUrl
        }
      }
    }
    ... on Response {
      message
      status
      success
      token
      hash
      signedUrl
      userId
      shared
      __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showroom"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "showroom",
    "variableName": "showroom"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainHeading",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subHeading",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverpagesIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NestedContent",
          "kind": "LinkedField",
          "name": "content",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "presentationId",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Showroom",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shared",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditShowroomMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editShowroom",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditShowroomMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editShowroom",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dcac03678517d8e7029ed99ddd6d0b67",
    "id": null,
    "metadata": {},
    "name": "EditShowroomMutation",
    "operationKind": "mutation",
    "text": "mutation EditShowroomMutation(\n  $showroom: editShowroom\n) {\n  editShowroom(showroom: $showroom) {\n    __typename\n    ... on Showroom {\n      _id\n      id\n      name\n      mainHeading\n      subHeading\n      headerImage\n      templateId\n      tags\n      link\n      creator\n      coverpagesIds\n      backgroundImage\n      accountId\n      createdAt\n      content {\n        name\n        content {\n          image\n          mainHeading\n          presentationId\n          subHeading\n          imageUrl\n        }\n      }\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      hash\n      signedUrl\n      userId\n      shared\n      __typename\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7cb96f77b2dbef64275e7204b5bfefa';

module.exports = node;
