import { useState, useEffect } from 'react'
import { ReactComponent as ArrowRight } from "../../../../assets/images/icon-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/icon-arrow-left.svg";
import { ReactComponent as ExitScreen } from "../../../../assets/images/icon-remove-fullscreen.svg";
import { ReactComponent as FullScreen } from "../../../../assets/images/fullscreen.svg";
import { ReactComponent as Share } from "../../../../assets/images/shareicon.svg";
import { ReactComponent as ShowSlide } from "../../../../assets/images/icon-slide-show.svg";
import { DocumentViewer } from 'react-documents';
import { VideoPlayer } from "@learnio-as/video-player";
import FilesQuery from 'graphql/queries/GetFilesQuery';
import { useQuery } from 'relay-hooks';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './PresentationSlider.scss';
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import checkTypes from '../../../../helpers/checkTypes';
import { STATIC_URL } from 'constants/env';
import { getClientData } from 'helpers/queryHandler';
import clientOnly from "graphql/queries/clientOnly";
import Sharing from '../ShareModal/Sharing';
import ShareableLink from '../ShareModal/shareableLink';
import BackBtn from 'components/ui/molecules/BackBtn/BackBtn';
import { usePresentationFilesOrdering } from 'hooks/useFileHandler';
import {ReactComponent as Arrow} from "assets/images/arrow.svg"
import txtthumbnail from "assets/images/txt.jpg";
export default function PresentationSlider({ setSliderPopUp,nextPresentationId, presentationId, isMicrosite , setViewingItem, presentationFiles, showroomId, nextPres, bgImage }) {

    const [current, setCurrent] = useState(0);
    const [ImgSlider, setImgSlider] = useState(false)
    const [hoveredDiv, setHoveredDiv] = useState(false)
    const [text, setText] = useState(false)
    const [shareableLink, setShareableLink] = useState(null);
    const [share, setShare] = useState(false);
    const isSharingMode = window.location.pathname.includes("/share/");
    const [fullVideo,setFullVideo] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const sortedData = 
    usePresentationFilesOrdering(
         presentationId? presentationId : [],
         presentationFiles || []
       )
    const portal = getClientData(useQuery, clientOnly, {}, "Portal");
    const profile = getClientData(useQuery, clientOnly, {}, "Profile");

    let arrOfTypes = [];
    for (let i = 0; i < sortedData?.length; i++) {
        arrOfTypes.push(checkTypes(sortedData[i].fileType))
    }

    useEffect(() => {
       if(ImgSlider===true){    
            setText(true);
            setTimeout(() => {
                setText(false);
            }, 2800);
        }
    }, [ImgSlider])

    const thumbnailGenerator = (file, index) => {
        if (file.thumbnailUrl) { 
            return STATIC_URL + file.thumbnailUrl 
        }
        else {
            if (arrOfTypes[index] === 'image') 
            return `${STATIC_URL}${file?.fileUrl.replace(/\s/g, '%20')}`;

            else if (arrOfTypes[index] === 'pdf') 
            return 'https://cdn.cf.masterwizr.com/pdfthumbnail_oycrij.png';

            else if (arrOfTypes[index] === 'word' || file.fileUrl.includes("/document/")) 
            return 'https://cdn.cf.masterwizr.com/wordthumbnail_klkmyr.png';

            else if (arrOfTypes[index] === 'powerpoint' || file.fileUrl.includes('/presentation/')) 
            return 'https://cdn.cf.masterwizr.com/pptthumbnail_jqrrk9.png';

            else if (arrOfTypes[index] === 'spreadsheet' || file.fileUrl.includes('/spreadsheets/')) 
            return 'https://cdn.cf.masterwizr.com/excelthumbnail_zxgjwn.png';

            else if (arrOfTypes[index] === 'text/plain') 
             return `${STATIC_URL}${file?.fileUrl.replace(/\s/g, '%20')}`;
            else 
            return null;
        }
    }  

    const nextSlide = () => {
        setCurrent(current === sortedData?.length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? sortedData?.length - 1 : current - 1);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.keyCode === 37) {
            prevSlide();
          } else if (event.keyCode === 39) {
            nextSlide();
          }
        };
      
        window.addEventListener("keydown", handleKeyDown);
      
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [current, nextSlide, prevSlide]);

    const closeSliderPopUp = () => {
        setSliderPopUp(false)
        if(isMicrosite===true){
            setViewingItem(false)
        }
        document.body.style.overflow = 'auto';
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    }

    const exitHandler = (e) => {
        if (!document.fullscreenElement 
            && !document.webkitIsFullScreen 
            && !document.mozFullScreen 
            && !document.msFullscreenElement) {
            closeSliderPopUp()
        }
      };
    
      useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
        return () => {
          document.removeEventListener('fullscreenchange', exitHandler);
          document.removeEventListener('webkitfullscreenchange', exitHandler);
          document.removeEventListener('mozfullscreenchange', exitHandler);
          document.removeEventListener('MSFullscreenChange', exitHandler);
        }
      }, []);

    const arrowController = (e) => {
        if (e.key === 'ArrowLeft') {
            prevSlide()
        } else if (e.key === 'ArrowRight') {
            nextSlide()
        }
    }
    const isLastSlide = current === sortedData?.length - 1;
    const handleNextPres=()=>{
        nextPres();
        setCurrent(0);
    }
    const handleVideoClick = (e) => {
        e.stopPropagation();
    };
    return (
        <div 
        style={
            arrOfTypes[current] === 'text' ? {backgroundColor: `white`} : {backgroundColor: `black`}
        }
        className="slider-fixed-popup" >
            {isLastSlide && nextPresentationId!==null && <button className='nextPresBtn' onClick={handleNextPres}>Next <Arrow/></button>}
            <BackBtn extraClass="backbtnslider" isPreview={true}  onClick={() => closeSliderPopUp()}/>
            <div className='slider-popup-container'
                style={arrOfTypes[current] === 'image' ? { backgroundImage: `url("${STATIC_URL}${sortedData[current].fileUrl.replace(/\s/g, '%20')}")` } : null}
                onKeyDown={(e) => arrowController(e)}
                tabIndex="0"
            >
                {
                    sortedData?.length > 1 ? (
                        <>
                            <div className="arrow-container">
                                <button className="prev" onClick={() => prevSlide()} type="button">
                                    <ArrowLeft />
                                </button>
                            </div>
                            <div className="arrow-container">
                                <button className="next" onClick={() => nextSlide()} type="button">
                                    <ArrowRight />
                                </button>
                            </div>
                        </>
                    ) : null
                }
                {
                    arrOfTypes[current] === 'video' ? (
                        <div className={`non-img video-player-slider ${fullVideo ? `video-slider-player-full` : ``}`} onClick={()=>handleVideoClick()}>
                            <div style={{
                                backgroundImage:`url(${bgImage})`
                            }} className="video-player-slider-backdrop">
                                </div>
                                <div className='video-player-container'>
                                <button className='fullscreen-slider-video'
                                onClick={()=>setFullVideo(!fullVideo)}
                                >{fullVideo ? <ExitScreen/> : <FullScreen/>}</button>
                            <VideoPlayer
                             src={`${STATIC_URL}${sortedData[current]?.fileUrl.replace(/\s/g, '%20')}`} />
                        </div>
                        </div>
                    ) : null
                }
                {
                    (arrOfTypes[current] === 'text') ? (
                        <div className='non-img document-player-slider'>
                           <iframe
                             src={`${STATIC_URL}${sortedData[current]?.fileUrl.replace(/\s/g, '%20')}`}
                             title="iframe"
                             loading="lazy"
                             />
                        </div>
                    ) : null
                }
                         {
                    arrOfTypes[current] === 'links' ? (
                        <div className='non-img video-player-slider'>
                            <iframe
                             src={`${sortedData[current]?.fileUrl}`} 
                             title="iframe"
                             loading="lazy"
                             /> 
                        </div>
                    ) : null
                }
                {
                    arrOfTypes[current] === 'pdf' ? (
                        <div className='non-img document-player-slider'>
                            <Worker
                                textLayerRendered={false}
                                workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
                            >
                                <Viewer
                                    fileUrl={`${STATIC_URL}${sortedData[current].fileUrl.replace(/\s/g, '%20')}`}
                                    defaultScale={1}
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                            </Worker>
                        </div>
                    ) : null
                }
                {
                    (arrOfTypes[current] === 'powerpoint' || arrOfTypes[current] === 'word' || arrOfTypes[current] === 'spreadsheet') ? (
                        <div className='non-img document-player-slider'>
                            <DocumentViewer
                                url={`${STATIC_URL}${sortedData[current].fileUrl.replace(/\s/g, '%20')}`}
                                viewer="office"
                            />
                        </div>
                    ) : null
                }

                <div className='slider-footer-control'>
                    <div className='slider-logo'>
                        <p className="slider-pagination">{current + 1}/{sortedData?.length}</p>
                        <p className='mw-logo'>
                            Powered by
                            <a href="https://mwizr.com" referrerPolicy="no-referrer" target="_blank" rel="noreferrer">
                                MASTER WIZR
                            </a>
                        </p>
                    </div>
                    <div className='slider-control'>
                        <div className='svg-container'>
                            <p className='slider-action-indicator'>Turn {ImgSlider ? 'OFF' : 'ON'} Slider</p>
                            <ShowSlide className={`${ImgSlider ? 'svgOn' : ''}`}  onClick={() => setImgSlider(!ImgSlider)} />
                            {text && <p  className="timeoutText">Hover on bottom middle of your screen for slider</p>}

                        </div>
                        {!isSharingMode && <div className='svg-container'>
                            <p className='slider-action-indicator'>Share show room</p>
                            <Share onClick={() => setShare(true)}/>
                        </div>}
                        <div className='svg-container'>
                            <p className='slider-action-indicator'>
                                {document.fullscreenEnabled ? 'Exit ' : 'Trigger '}
                                Full Screen
                            </p>
                            <ExitScreen onClick={() => closeSliderPopUp()} />
                        </div>
                    </div>
                </div>
                <div className='slider-img-hovering-container'
                    style={{display: ImgSlider ? 'block' : 'none'}}
                    onMouseEnter={() => setHoveredDiv(true)}
                    onMouseLeave={() => setHoveredDiv(false)}>
                    {
                        ImgSlider && hoveredDiv ? (
                            <div
                                className="slider-images open">
                                <Splide
                                    options=
                                    {{
                                        arrows: false,
                                        pagination: false,
                                        rewind: true,
                                        gap: '5px',
                                        perPage: 8,
                                    }}
                                    aria-labelledby="reactivity-example-heading"
                                >
                                    {sortedData?.map((img, index) => (
                                        <SplideSlide key={index}>
                                           {
                                            arrOfTypes[index] === 'video' ? (
                                            <div
                                            className={index === current ? 'video-thumbnail active' : 'video-thumbnail'}
                                            >
                                                <VideoPlayer
                                                className={index === current ? 'active' : ''}
                                                onClick={() => setCurrent(index)}
                                                src={`${STATIC_URL}${sortedData[index]?.fileUrl.replace(/\s/g, '%20')}`} />
                                            </div> 
                                            )
                                            : thumbnailGenerator(img,index) ? (
                                                <img
                                                className={index === current ? 'active' : ''}
                                                onClick={() => setCurrent(index)}
                                                src={thumbnailGenerator(img, index)}
                                                alt={`img ${index}`}
                                            />
                                            ) :
                                            arrOfTypes[index] === 'text' ? (
                                                 <img
                                                className={index === current ? 'active' : ''}
                                                onClick={() => setCurrent(index)}
                                                src={txtthumbnail}
                                                alt={`img ${index}`}
                                            />
                                            ) :
                                            arrOfTypes[index] === 'links' && (
                                                <div
                                                  onClick={() => setCurrent(index)}
                                                  className={index === current ? 'video-thumbnail active' : 'video-thumbnail'}
                                                >
                                                 <iframe
                                                  src={`${sortedData[index]?.fileUrl}#toolbar=0`}
                                                  title="iframe"
                                                  loading="lazy"
                                               />
                                               </div>
                                            )
                                           } 
                                        </SplideSlide>
                                    ))
                                    }
                                </Splide>
                            </div>
                        ) : (null)
                    }
                </div>
                
                {share && (<Sharing
                        itemName={"showroom"}
                        portalId={portal.portalId}
                        accountId={profile.accountId}
                        itemId={showroomId}
                        handleClose={() => setShare(false)}
                        onUrlCreated={url => {
                        setShareableLink(url);
                        setShare(false);
                        }}
                    />)
                }
                {shareableLink && <ShareableLink 
                    setShareableLink={setShareableLink}
                    shareableLink={shareableLink}
                />}
            </div>
        </div>
    )
}
