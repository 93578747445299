import { SlideCustomizer } from "@learnio-as/customizer";
import PortalQuery from "graphql/queries/GetPortalQuery";
import useWatchPortalUpdates from "graphql/subscriptions/useWatchPortalUpdates";
import { WatchPortalUpdates } from "graphql/subscriptions/Watch";
import React from "react";
import { useEffect } from "react";
import { useQuery } from "relay-hooks";
import { getClientData } from "../../../../helpers/queryHandler";
import clientOnly from "../../../../graphql/queries/clientOnly";

function Customizer({ data, activePortal, activeRow, templateId, ...props }) {
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  let  locale = profile?.locale?.toLowerCase() || 'en';
  const [activePortalId, setActivePortalId] = React.useState(
    data[activePortal]?._id
  );
  const [cusData, setCusData] = React.useState(data);

  const replaceItemById = (arr, id, newValue) => {
    return arr.map(item => (item._id === id ? { ...item, ...newValue } : item));
  };
  const findIndexById = (arr, id) => {
    return arr.findIndex(item => item._id === id);
  };
  const replaceItemByName = (arr, name, newValue) => {
    return arr.map(item =>
      item.name === name ? { ...item, ...newValue } : item
    );
  };

  useWatchPortalUpdates(activePortalId, WatchPortalUpdates, updates => {
    const data = replaceItemByName(cusData, updates.name, updates);
    setCusData(data);
    // setKey(key + 1);
  });

  const { data: portalQueryResponse, isLoading } = useQuery(
    PortalQuery,
    {
      id: activePortalId,
    },
    {
      fetchPolicy: "store-and-network",
      skip: !activePortalId,
    }
  );
  useEffect(() => {
    if (!portalQueryResponse?.portal) return;
    const data = replaceItemById(
      cusData,
      activePortalId,
      portalQueryResponse.portal
    );
    setCusData(data);
    // setKey(key + 1);
  }, [portalQueryResponse]);

  return (
    <>
      <div
        className="studiocustomizer"
        style={{
          position: "absolute",
          top: 0,
          zIndex: "99999999",
          backgroundColor: "#212121",
          maxHeight: "100vh !important",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          left: 0,
        }}
      >
        <SlideCustomizer
          selectedPortal={selectedPortalId => {
            setActivePortalId(selectedPortalId);
          }}
          activePortal={findIndexById(data, activePortalId)}
          data={cusData}
          activeRow={activeRow}
          templateId={templateId}
          locale={locale}
          {...props}
        />
      </div>
    </>
  );
}

export default Customizer;
