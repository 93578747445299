import List from "containers/lists";
import { useQuery, useSubscription } from "relay-hooks";
import ShowroomsQuery from "graphql/queries/GetShowroomsQuery";
import DeleteShowroomMutation from "graphql/mutations/DeleteShowroomMutation";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditShowroomMutation from "graphql/mutations/EditShowroomMutation";
import PortalsQuery from "graphql/queries/GetPortalsQuery";
import { showErrorToast, showSuccessToast } from "@learnio-as/toast/";
import logo from "assets/images/showroom_white.png";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import ShowroomsByPortalQuery from "graphql/queries/showroom/ShowroomsByPortal";
import { useParams } from "react-router-dom";
import EditMicrositeMutation from "graphql/mutations/EditMicrositeMutation";
import IsLoading from "../IsLoading";
import responseHandler from "helpers/responseHandler";
import ShowroomQuery from "graphql/queries/GetShowroomQuery";
import { useTranslation } from "react-i18next";
export function ShowroomsList() {
  const navigation = useNavigate();
  const [showrooms, setShowrooms] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editMicrositeMode, setEditMicrositeMode] = useState(false);
  const { id, coverpageid, selectedCover, templateId } = useParams();
  const [selecectedListShowrooms, setSelectedListShowrooms] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const micrositeId = urlParams.get("micrositeid");
  const { t } = useTranslation();
  const [isCreateMode, setIsCreateMode] = useState(
    window.location.pathname.includes("create-microsite")
  );

  //Get Actual Portal
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  //Fetch Data with actual PortalId
  const { data, isLoading } = useQuery(
    ShowroomsByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );

  useEffect(() => {
    if (isLoading) return;
    setShowrooms(data?.showroomsByPortalId?.showrooms);
  }, [isLoading, data?.showroomsByPortalId?.showrooms]);
  useEffect(() => {
    if (id !== undefined) {
      setEditMicrositeMode(true);
      const arr = id.split(",");
      setSelectedListShowrooms(arr);
    }
  }, [id]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteFunc = id => {
    setIsDeleting(true);
    DeleteShowroomMutation(id, response => {
      setShowDeleteModal(!showDeleteModal);
      responseHandler(
        response,
        "Showroom deleted successfully",
        () => {
          setIsDeleting(false);
        },
        undefined,
        () => {
          setIsDeleting(false);
        }
      );
    });
  };

  const handleEdit = showroom => {
    let content = JSON.parse(JSON.stringify(showroom.content));
    content.forEach(item => {
      item.content.forEach(item2 => {
        delete item2.files;
      });
    });
    showroom.content = content;
    const {
      _id: editShowroomId,
      id,
      updatedAt,
      templateId,
      accountId,
      createdAt,
      creator,
      __typename,
      ...edited
    } = showroom;
    let errors = "";
    if (!edited.name) {
      errors = "Name";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} is required`);
    }
    EditShowroomMutation(editShowroomId, edited, response => {
      responseHandler(response, t("showroom-updated"));
    });
  };

  const editMicroste = microsite => {
    if (!isCreateMode) {
      let editingMicrosite = microsite;
      editingMicrosite._id = micrositeId;
      const {
        updatedAt,
        accountId,
        createdAt,
        creator,
        id,
        __typename,
        ...edited
      } = editingMicrosite;
      let errors = "";
      EditMicrositeMutation(edited, response => {
        responseHandler(
          response,
          "Microsite Updated Successfully",
          ({ _id, showroomsIds, templateId, coverpageId }) => {
            navigation(
              `/microsite/${showroomsIds.toString()}/${templateId}?id=${coverpageId}&micrositeid=${_id}`,
              { replace: true }
            );
          }
        );
      });
    } else {
      navigation(
        `/microsite/${microsite.showroomsIds}/${templateId}?id=${selectedCover}`,
        { replace: true }
      );
    }
  };

  if (isLoading) return <IsLoading isShowroom/>;
  if (!showrooms) return null;

  return (
    <>
      {!editMicrositeMode ? (
        <List
          isList
          title="Show Room"
          bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/covert2.png"
          items={showrooms}
          isLoading={isLoading}
          showrooms={true}
          deleteFunc={deleteFunc}
          handleEdit={handleEdit}
          maxPerPage={9}
          logo={logo}
          top={"-2px"}
          isDeleting={isDeleting}
        ></List>
      ) : !isCreateMode ? (
        <List
          isList
          title="My Show Rooms"
          bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/covert2.png"
          items={showrooms}
          isLoading={isLoading}
          showrooms={true}
          deleteFunc={deleteFunc}
          handleEdit={handleEdit}
          maxPerPage={9}
          logo={logo}
          top={"-2px"}
          editMicrositeMode={editMicrositeMode}
          selecectedListShowrooms={selecectedListShowrooms}
          editMicroste={editMicroste}
        ></List>
      ) : (
        <List
          isList
          title="My Show Rooms"
          bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/covert2.png"
          items={showrooms}
          isLoading={isLoading}
          showrooms={true}
          deleteFunc={deleteFunc}
          handleEdit={handleEdit}
          maxPerPage={9}
          logo={logo}
          top={"-2px"}
          isCreateMode={isCreateMode}
          selecectedListShowrooms={selecectedListShowrooms}
          editMicroste={editMicroste}
        ></List>
      )}
    </>
  );
}

export default ShowroomsList;
