import { showErrorToast, showSuccessToast } from "@learnio-as/toast";

const generateErrorMessage = error => {
  const errors = {
    400: "Bad Request",
    401: "You are not authorized to perform this action",
    403: "You are not authorized to perform this action",
    404: "The requested resource could not be found",
    500: "An error occurred while processing your request",
    503: "The server is currently unavailable",
  };
  const message = error.message || errors[error.status];
  showErrorToast(message);
};

const generateSuccessMessage = message => {
  showSuccessToast(message);
};
const factory = { generateErrorMessage, generateSuccessMessage }
export default factory;
