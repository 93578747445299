export const wordFile = ['doc', 'docx', 'dotx', 'docm', 'odt', 'rtf', 'wps'];
export const excelFile = ['xls', 'xlsx', 'ods', 'csv', 'xltm', 'xtls', 'xtls'];
export const powerpointFile = ['ppt', 'pptx', 'odp', 'sldx', 'sldm', 'pps', 'ppsx', 'ppsm', 'potx', 'potm', 'pot', 'odp', 'otp'];
export const pdfFile = ['pdf', 'PDF'];
export const imageFile = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'svg', 'ico', 'webp', 'jfif', 'pjpeg', 'pjp', 'avif', 'apng'];
export const videoFile = ['mp4', 'mov', 'wmv', 'ogg', 'webm','mpeg','quicktime'];
export const links = ['vimeo', 'youtube', 'link'];

const checkTypes = (file) => {
    if (
        file?.includes(".mp3")
    ) {
        return "audio"
    }
    if (
        videoFile?.includes(file?.split('/')[1]) || videoFile?.includes(file?.split('.').pop())
    ) {
        return "video"
    }
    else if (
        imageFile?.includes(file?.split('/')[1]) || imageFile?.includes(file?.split('.').pop())) {
        return "image"
    }
    else if (
        wordFile?.includes(file?.split('/')[1])  || file?.includes('word') || wordFile?.includes(file?.split('.').pop()) || file?.includes('/document/')) {
        return "word"
    }
    else if (
        excelFile?.includes(file?.split('/')[1]) || file?.includes('excel') || excelFile?.includes(file?.split('.').pop()) || file?.includes('/spreadsheets/')) {
            return "spreadsheet"
        }
    else if (
        powerpointFile?.includes(file?.split('/')[1]) || file?.includes('powerpoint') || powerpointFile?.includes(file?.split('.').pop()) || file?.includes('/presentation/')) {
            return "powerpoint"
        }
    else if (
        pdfFile?.includes(file?.split('/')[1]) || pdfFile?.includes(file?.split('.').pop())) {
            return "pdf"
        }
      else  if (
            file?.includes("text/plain") || file?.includes(".txt")
        ) {
            return "text"
        }
    else if (
        links?.includes("youtube") || links?.includes("vimeo") || links?.includes("link")) {
            return "links"
        }
    else {
        return null
    }
}

export default checkTypes;