
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CancelButton = props => {
    const {
        handleCancel
    } = props;
    const { t } = useTranslation();
    return (
        <button
            className="cover-button cancel-button"
            onClick={handleCancel}
        >
            {t("cancel")}
        </button>
    );
};

CancelButton.propTypes = {
    handleCancel: PropTypes.func
};

export default CancelButton;
