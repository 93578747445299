import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UniversalMenu } from "@learnio-as/universal-menu";
import {
  deleteAuthData,
  switchActiveProfile,
} from "helpers/authHandler";
import { getAccountId, getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import selectData from "graphql/mutations/selectData";
import Cookies from "universal-cookie";
import { useHasModuleAccess } from "hooks/useModuleAccess";
import { useTour } from "@reactour/tour";
import { LIBRARY_URL, STUDIO_URL } from "constants/env";

const initialValues = {
  CURRENT_ORG: "engineering",
  mode: "dark",
  displayValue: "-100",
  accounts: [
    "Intranet",
    "Sales",
    "HR",
    "Training",
    "Community",
    "Manuals",
    "Management",
    "Marketing",
    "Investors",
  ],
};
export default function UniversalMenuComponent({ showMenu, handleShowMenu }) {
  const [values, setProps] = useState(initialValues);
  const navigate = useNavigate();
  const portals = getClientData(useQuery, clientOnly, {}, "Portals");
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const sidebar = getClientData(useQuery, clientOnly, {}, "Sidebar");
  const modals = getClientData(useQuery, clientOnly, {}, "Modal");
  const accountId = getAccountId();
  const cookies = new Cookies();
  const licence = profile?.accountInfo?.licenseInfo?.licenseType;
  const { steps, setIsOpen, setCurrentStep } = useTour();
  const location = useLocation();
  function handleActivePage() {
    setIsOpen(true);
    const currentPath = location.pathname.toLowerCase();
    const startIndex = steps?.findIndex(
      step =>
        step?.pathname && currentPath?.includes(step?.pathname?.toLowerCase())
    );
    setCurrentStep(startIndex);
    selectData({ ...sidebar, show: true }, "Sidebar");
    selectData({ ...modals, tour: true }, "Modal");
  }

  useEffect(() => {
    selectData({ ...sidebar, show: true }, "Sidebar");
  }, []);
  useEffect(() => {
    if (profile?.accountInfo?.theme) {
      const theme = profile.accountInfo.theme;
      const themeColor = theme === "BRIGHT" ? "light" : theme.toLowerCase();
      selectData({ ...sidebar, mode: `${themeColor}` }, "Sidebar");
      for (let i = 0; i < document.body.classList.length; i++) {
        if (document.body.classList[i].includes("-mode")) {
          document.body.classList.remove(document.body.classList[i]);
        }
      }
      document.body.classList.add(`${theme.toLowerCase()}-mode`);
    }
  }, []);




  const handleCreateShowroom = () => console.log("callcreate show room fn");
  const handleCreateCoverPages = () => console.log("call create cover page fn");
  const toggleMenu = () => {
    selectData({ ...sidebar, show: !sidebar?.show }, "Sidebar");
  };
  const logout = () => {
    deleteAuthData();
  };
  const switchAccount = portal => {
    let portal_id = portal.split("-")[1];
    const array_portals = Object.values(profile?.accessedPortals);
    const result = array_portals.find(item => item.portalId === portal_id);
    selectData(result, "Portal");
    switchActiveProfile(true, result?.portalId, "portalId");
  };

  useEffect(() => {
    if (values.displayValue === "-100") {
      document.body.classList.add("universalmenu-closed");
    } else if (values.displayValue === "0") {
      document.body.classList.remove("universalmenu-closed");
    }
  }, [values.displayValue]);
  const hasTier = useHasModuleAccess()[2];
  return (
    <UniversalMenu
      {...{
        ...values,
        logout,
        toggleMenu,
        switchAccount,
        _redirect: navigate,
        accessedPortals: profile?.accessedPortals || [],
        CURRENT_ORG: `${profile?.accountId || ""}-${portal?.portalId || portals?.[0]?.portalId || ""}`,
        activeProfile: cookies.get("activeProfile"),
        profile,
        accountRole: profile?.accountRole,
        CURRENT_PORTAL: portal
          ? portal?.portalId
          : portals
          ? portals[0]?.portalId
          : "",
        handleActivePage,
        displayValue: sidebar?.show ? "0" : "-100",
        mode: sidebar?.mode || "dark",
        redirectURL: "studio",
        licence,
        hasTier,
        STUDIO_URL,
        moduleIndex:'studio',
        LIBRARY_URL,
      }}
    />
  );
}
UniversalMenuComponent.propTypes = {
  showMenu: PropTypes.bool,
  handleShowMenu: PropTypes.func,
};
