import React from "react";
import styles from "./BackBtn.module.scss";
import { ReactComponent as Back } from "assets/images/backicon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LOGIN_URL } from "constants/env";

function BackBtn({ onClick, isPreview, isMicrosite, magazineClick, extraClass }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    if (onClick) {
      onClick();
    } else if (magazineClick) {
      magazineClick();
    } else {
      navigate(-1);
    }
  };
if (window?.history?.state?.idx === 0) {
  return null;
}
  return (
    <div
      onClick={handleBackClick}
      className={`${styles.btn} ${
        (isPreview || isMicrosite) && styles.previewbtn
      } back-btn ${styles[extraClass]}`}
    >
      <Back />
      <span>{t("Back")}</span>
    </div>
  );
}

export default BackBtn;