import styled from "styled-components";
import face from "./face.png";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const Wrapper = styled.div`
    height: 80vh;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    flex-direction: column;
`

const Image = styled.img`
    width: 200px;
`
const Text = styled.p`
    @media screen and (min-width: 2000px) {
        font-size: 22px;
    }
    @media screen and (min-width: 3000px) {
        font-size: 30px;
    }
    @media screen and (min-width: 4000px) {
        font-size: 45px;
    }
`

export function EmptyList(){
  const {t} = useTranslation();
  const location = useLocation();
    return(
        <Wrapper>
            <Image src={face} />
            <Text>{t("sorry-it-is-lonely-here")}</Text>
            {location?.pathname === "/showrooms" ? 
            <div className="empty-list-link">
                <Link to="/showroom-templates">{t("Create")} Showroom</Link>
            </div>
            : location?.pathname==="/cover-pages"? 
            <div className="empty-list-link">
                <Link to="/coverpage-templates">{t("Create")} Cover Page</Link>
            </div>
            : location?.pathname==="/microsites" ?
            <div className="empty-list-link">
                <Link to="/showrooms">{t("Create Microsite")}</Link>
            </div> :""
            }
            
        </Wrapper>
    )
}

export default EmptyList;
