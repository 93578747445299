// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";

// 2
const mutation = graphql`
  mutation EditShowroomMutation($showroom: editShowroom) {
    editShowroom(showroom: $showroom) {
      ... on Showroom {
        _id
        id
        name
        mainHeading
        subHeading
        headerImage
        templateId
        tags
        link
        creator
        coverpagesIds
        backgroundImage
        accountId
        createdAt
        content{
          name
          content{
            image
          mainHeading
          presentationId
          subHeading
          imageUrl
          }
        }
      }

      ... on Response {
        message
        status
        success
        token
        hash
        signedUrl
        userId
        shared
        __typename

      }
    }
  }
`;

export default (editShowroomId, showroom, callback = (response, error) => {}) => {
  const variables = {
    showroom: {
      ...showroom,
      id: editShowroomId,
    },
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response, null);
    },
    onError: (err) => {
      callback(null, err);
    },
    updater: (store) => {
      window.store = store; 
    },
  });
};
