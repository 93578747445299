import "./Editor.scss";
import styled from "styled-components";
import { ReactComponent as IconLogo } from "../../../../assets/images/align left.svg";
import { ReactComponent as Align } from "../../../../assets/images/alignleft1.svg";
import { ReactComponent as B } from "../../../../assets/images/B.svg";
import { ReactComponent as Center } from "../../../../assets/images/center.svg";
import { ReactComponent as Ellipse } from "../../../../assets/images/Ellipse 895.svg";
import SaveIcon from "./icons/SaveIcon";
import DeleteIcon from "./icons/DeleteIcon";
import BackIcon from "./icons/BackIcon";
import { ReactComponent as I } from "../../../../assets/images/I.svg";
import { ReactComponent as Move } from "../../../../assets/images/move icon.svg";
import { ReactComponent as T } from "../../../../assets/images/T.svg";
import { ReactComponent as Text } from "../../../../assets/images/text box.svg";
import { ReactComponent as U } from "../../../../assets/images/U.svg";
import { ReactComponent as Polygon } from "../../../../assets/images/Polygon 60.svg";
import { ReactComponent as Group } from "../../../../assets/images/Group 10751.svg";
import { ReactComponent as Rectangle } from "../../../../assets/images/Rectangle 1188.svg";
import { ReactComponent as Dropdown } from "../../../../assets/images/drop down.svg";
import { ReactComponent as Iconfolder } from "../../../../assets/images/icon-folder-grey-1.svg";
import { useState } from "react";
import { ReactComponent as Preview } from "../../../../assets/images/icon-preview-grey-1.svg";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import DeleteModal from "containers/Modals/deleteModal";
import DeleteShowroomMutation from "graphql/mutations/DeleteShowroomMutation";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "@learnio-as/toast";
import { ReactComponent as Add } from "assets/images/add.svg";
import ImgModal from "../ImgModal/ImgModal";
import Tags from "../../../../containers/Tags";
import { Title, ShareButton } from "../DesignEditor/styles";
import Input from "../Input/input";
import responseHandler from "helpers/responseHandler";

const Button = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(256, 256, 256, 0.08);
  right: 5px;

  &:hover {
    background: rgba(256, 256, 256, 0.7);
  }
  @media screen and (min-width: 2560px){
      transform: scale(1.4);
  }
  @media screen and (min-width: 3000px){
    transform: scale(2);
  }
  @media screen and (min-width: 4000px){
    transform: scale(2.5);
  }
`;

const DeleteButton = styled(Button)`
  top: 5px;
  .delete-icon{
    margin-left: 9px;
    margin-top: 6px;
    transform: none!important;
  }
`;

const SaveButton = styled(Button)`
  top: 5px;
  .save-icon{
    transform:none!important;
  }
`;
function Editor({
  handleFile,
  className,
  coverpageId,
  showroomId,
  handleEditCoverpage,
  onClickPreview,
  id,
  tag,
  tags,
  handleKeyDown,
  handleChangeTag,
  handleRemoveTag,
  handleSaveCoverpage,
  checkRequiredFields = () => true,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigation = useNavigate();
  const [popup, setPopup] = useState(false);
  const hidePopup = () => {
    setPopup(false);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const deleteShowroomByID = () => {
    if (showroomId) {
      DeleteShowroomMutation(showroomId, response => {
        responseHandler(response, "Showroom deleted successfully", () =>{
          setShowDeleteModal(!showDeleteModal);
          navigation('/showrooms');
        })
      });
    }
    // TODO Disable delete if Id is present.
  };
  return (
    <>
      <div className={`editor ${className}`}>
        <div className="inner-icons">
          <div className="icons-edit">
            {/* <BackIcon
              onClick={() =>
                navigation(showroomId ? "/showrooms" : "/templates")
              }
            /> */}
            {/* <div className="centered-part">
              <div className="borders-1">
                <Polygon />
                <Rectangle />
                <Ellipse />
              </div>
              <div className="borders">
                <Move />
                <T />
                <Text />
              </div>
              <div className="borders">
                <B />
                <I />
                <U />
              </div>
              <div className="borders">
                <IconLogo />
                <Center />
                <Align />
              </div>
              <div className="borders">
                <div className="dropdowns">
                  <p>16</p>
                  <Dropdown />
                </div>
                <div className="dropdowns">
                  <p>Arial</p>
                  <Dropdown />
                </div>
              </div>
              <Group />
              {showroomId && (
                <SaveButton onClick={() => handleEditCoverpage()}>
                  <SaveIcon />
                </SaveButton>
              )}
              {showroomId && (
                <DeleteButton onClick={toggleDeleteModal}>
                  <DeleteIcon />
                </DeleteButton>
              )}
              {coverpageId && (
                <DeleteButton onClick={toggleDeleteModal}>
                  <DeleteIcon />
                </DeleteButton>
              )}
              {showDeleteModal && (
                <DeleteModal
                  text={`Are you sure you want to delete?`}
                  toggleModal={toggleDeleteModal}
                  deleteFunc={deleteShowroomByID}
                />
              )}
            </div> */}
            {/* <p className="content-slide link-edit">Add Link</p> */}
            <div className="icon-preview">
            {/* {(showroomId || coverpageId) && (
                <SaveButton onClick={() => handleEditCoverpage()}>
                  <SaveIcon />
                </SaveButton>
              )} */}
            {/* { id && (
                <SaveButton onClick={() => handleEditCoverpage()}>
                  <SaveIcon className="save-icon" />
                </SaveButton>
              )}
              { id || coverpageId? (
                <DeleteButton onClick={toggleDeleteModal}>
                  <DeleteIcon className="delete-icon"/>
                </DeleteButton>
              )
              :
              <></>
            } */}
              {showDeleteModal && (
                <DeleteModal
                  text={`Are you sure you want to delete?`}
                  toggleModal={toggleDeleteModal}
                  deleteFunc={deleteShowroomByID}
                />
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Editor;
Editor.propTypes = {
  handleFile: PropTypes.func,
};
